<!-- ----------------------------------------- template ----------------------------------------- -->
<template>
  <div class="view-root">
    <h3 style="margin-bottom: 20px;">订单列表</h3>
    <el-button type="primary" @click="add">新增</el-button>
    <el-table :data="tableData" style="margin-top: 20px">
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="类型" prop="type"></el-table-column>
      <el-table-column label="版本日期">
        <template slot-scope="scope">
          {{ dayjs(scope.row.version_time).format("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column label="是否公布">
        <template slot-scope="scope">
          {{ scope.row.public_status ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="primary" size="small"
            >修改</el-button
          >
          <el-button @click="remove(scope.row)" type="danger" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="修改"
      :visible.sync="showAdd"
      :close-on-click-modal="false"
    >
      <el-form class="myEditForm">
        <el-form-item label="名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.type">
            <el-option value="用户协议"></el-option>
            <el-option value="隐私协议"></el-option>
            <el-option value="等级说明"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本日期">
          <el-date-picker
            v-model="form.version_time"
            type="date"
            placeholder="选择日期"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="是否公布">
          <el-radio-group v-model="form.public_status">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="内容">
          <quill-editor
            class="editor"
            ref="myTextEditor"
            style="margin-top: 50px"
            v-model="form.content"
            :options="editorOption"
          >
          </quill-editor>
        </el-form-item>
        <el-form-item label="更新内容">
          <quill-editor
            class="editor"
            style="margin-top: 50px"
            ref="myTextEditor"
            v-model="form.update_content"
            :options="editorOption"
          >
          </quill-editor>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button
          type="primary"
          size="medium"
          @click="confirm"
          v-loading="loading"
          >确认</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<!-- ------------------------------------------ script ------------------------------------------ -->
<script>
import { getAgreements, removeAgreement, updateAgreement } from "../../api/api";
import dayjs from "dayjs";
export default {
  data() {
    return {
      dayjs,
      tableData: [],
      form: {},
      showAdd: false,
      id: "",
      loading: false,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getAgreements().then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.data;
        }
      });
    },
    add() {
      this.form = {
        name: "",
        type: "",
        version_time: "",
        public_status: "",
        content: "",
        update_content: "",
      };
      this.id = "";
      this.showAdd = true;
    },
    edit(data) {
      this.form = {
        name: data.name,
        type: data.type,
        version_time: data.version_time,
        public_status: data.public_status,
        content: data.content,
        update_content: data.update_content,
      };
      this.id = data._id.$id;
      this.showAdd = true;
    },
    confirm() {
      this.loading = true;
      updateAgreement({
        id: this.id,
        ...this.form,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.$message.success("更新成功");
            this.showAdd = false;
            this.fetchData();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove(data) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        removeAgreement({
          id: data._id.$id,
        }).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功");
            this.showAdd = false;
            this.fetchData();
          }
        });
      });
    },
  },
};
</script>

<!-- ------------------------------------------ style ------------------------------------------ -->

<style lang="scss" scoped>
.dataContainer {
  padding: 30px;
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 140px;
  line-height: 140px !important;
  text-align: center;
}
.avatar-uploader-icon1 {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px !important;
  text-align: center;
}
.avatar {
  width: 140px;
  height: 140px;
  display: block;
}
</style>
<style>
.ql-container {
  height: 180px !important;
}
</style>
